












    import {Component, Prop} from '@/decorators';
    import {PortalSettings} from '@/model/backend/campaign-api';
    import Vue from 'vue';
    import {Getter} from 'vuex-class';

    @Component({})
export default class ProductBullets extends Vue {
    @Prop({required: false})
    private image?: string;

    @Prop({required: false})
    private bulletPoints: string;

    @Prop({required: false, default: true})
    private optionalMobile: boolean;

    @Prop
    private alwaysProductImage: boolean;

    @Getter
    private settings: PortalSettings;

    private get showImage(): boolean {
        return this.alwaysProductImage || this.settings.showProductImage;
    }
}
