









import {Component, Prop} from '@/decorators';
import {ButtonObject} from '@/model/backend/campaign-api';
import {ButtonType} from '@/model/frontend/button';
import Vue from 'vue';

@Component
export default class Button extends Vue {
    @Prop({default: ButtonType.PRIMARY, type: String})
    private type: ButtonType;

    @Prop({required: false})
    private color?: string;

    @Prop
    private outline: boolean;

    @Prop
    private disabled: boolean;

    @Prop
    private fill: boolean;

    @Prop({required: false})
    private data: ButtonObject | null;

    private get styles(): object {
        if ((this.data || this.color) && this.type !== ButtonType.REQUEST) {
            return {backgroundColor: this.data ? this.data.color : this.color};
        }
        return {};
    }

    private get classes(): string[] {
        let classes = ['btn'];

        if (this.outline) {
            classes.push(`btn-outline-${this.type}`);
        } else {
            classes.push(`btn-${this.type}`);
        }

        if (this.disabled) {
            classes.push('disabled');
        }

        if (this.fill) {
            classes.push('fill');
        }

        if (this.type === ButtonType.REQUEST) {
            classes.push(`color-${this.color}`);
        }

        return classes;
    }
}
