var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-bullets row" }, [
    _vm.showImage
      ? _c(
          "div",
          {
            staticClass: "col-4 col-md-3 col-lg-2 d-md-block",
            class: {
              "d-xs-block d-sm-block d-md-none mb-3": _vm.optionalMobile
            }
          },
          [
            _c("img", {
              staticClass: "img-fluid",
              attrs: { src: _vm.image, alt: "Product Image" }
            })
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "col", class: { "d-md-block": _vm.showImage } }, [
      _vm.bulletPoints
        ? _c("div", {
            staticClass: "bullet-points",
            domProps: { innerHTML: _vm._s(_vm.bulletPoints) }
          })
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }