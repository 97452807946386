var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button" }, [
    _c(
      "button",
      {
        class: _vm.classes,
        style: _vm.styles,
        on: {
          click: function($event) {
            return _vm.$emit("click", $event)
          }
        }
      },
      [
        _vm._t("default"),
        _vm._v(
          "\n        " + _vm._s(_vm.data ? _vm.data.caption : "") + "\n    "
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }