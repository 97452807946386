export enum ButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
    LINK = 'link',
    CUSTOM = 'custom',
    REQUEST = 'request'
}
